<template>

	<container container_id="login" :collapse_on_mount="false" :classes="{ noheader : true }"><template slot="container-body">

		<form action="" @submit="submit">
			<div id="logo"><img src="@/assets/img/logo-random-ttfl.png" /></div>
			<input id="password" name="password" type="password" v-model="password" placeholder="Mot de passe" />
			<button type="submit">Connexion</button>
		</form>

	</template></container>

</template>

<script>

export default {

	name: 'LoginForm',

	props	: [
	],

	components	: {

		container : () => import( '@/components/container.vue' ),
		
	},

	data	: () => { return {

		password	: '',

	}},

	methods	: {

		submit( event ) {

			event.preventDefault()

			if ( this.password != 'RDMBTRV2022' )	return false

			this.$store.state.user.authenticated	= true

			this.$cookies.set( 'user', {
				'authenticated'	: true,
			}, '90d' )

			return false

		},

	},

	computed	: {
	},

	watch	: {
	},

	mounted() {
	},

}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	#login::v-deep {

		form {
			
			display: flex;
			flex-flow: row wrap;
			justify-items: flex-start;
			align-items: center;

			@include mobile() {
				margin-bottom: 15px;
			}

		}

		#logo {
			width: 40px;
			height: 40px;
		}

		input,
		button {

			height: 40px;
			padding-left: 15px;
			padding-right: 15px;
			margin-right: 15px;
			font-size: 16px;

			@include mobile() {
				flex: 100%;
				max-width: 100%;
				margin-top: 15px;
			}

			&::placeholder {
				font-size: 0.875em;
			}
		}

	}

</style>